.c-map {
  width: 100%;
  padding: 0;
  background-color: $color-bg;

  iframe {
    width: 100% !important;
    overflow: hidden;
    margin-bottom: -$spacing-normal;
  }
}
