.c-slider {
  position: relative;

  &__navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    background-color: $bg-white;
    border-radius: 100%;
    box-shadow: $global-shadow;
    width: 48px;
    height: 48px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity $global-transition-time $global-transition-effect;

    &--prev {
      transform: translateX(-15%);

      @include bp(medium) {
        transform: translateX(-50%);
      }
    }

    &--next {
      transform: translateX(15%);

      @include bp(medium) {
        transform: translateX(50%);
      }
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}

.swiper {
  padding-bottom: $spacing-big !important;
  margin-bottom: -$spacing-big !important;
}
