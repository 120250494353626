.c-match-block {
  $this: &;

  color: $text-black;
  text-decoration: none;
  max-width: 720px;

  &:hover {
    color: $text-black;
  }

  &__club {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-crest {
      width: auto;
      height: 50px !important;

      @include bp(medium) {
        height: 80px !important;
      }
    }

    &-name {
      font-size: 1rem;
      line-height: 1.25;
      margin-top: $spacing-small;
    }
  }

  &__details {
    font-weight: bold;
    display: inline-block;
    box-shadow: $global-shadow;

    @include bp-down(medium) {
      margin: 0 $spacing-normal;
    }
  }

  &__result {
    background-color: $bg-yellow;
    color: $text-black;
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
    padding: $spacing-small;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    justify-content: center;
    gap: $spacing-tiny;

    &--away {
      flex-direction: row-reverse;
    }
  }

  &__meta {
    color: $text-white;
    background-color: $bg-black;
    font-size: 0.85rem;
    line-height: 1;
    text-align: center;
    padding: $spacing-small;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &--away > :nth-child(1) {
    order: 3;
  }

  &--away > :nth-child(2) {
    order: 2;
  }

  &--away > :nth-child(3) {
    order: 1;
  }

  &--text-white {
    color: $text-white;

    &:hover {
      color: $text-white;
    }
  }

  &--large {
    #{$this}__club-name {
      font-size: 1.5rem;
      line-height: 1.25rem;
      margin-top: $spacing-big;
    }

    #{$this}__result {
      font-size: 1.5rem;
    }

    #{$this}__meta {
      font-size: 1rem;
    }
  }
}
