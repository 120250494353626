.c-article {
  background-color: $bg-white;
  border-radius: $global-radius;
  overflow: hidden;
  box-shadow: $global-shadow;
  position: relative;
  transition: opacity 250ms;
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.75;
  }

  &__image {
    padding-bottom: 56.25%;
    background-position: center center;
    background-size: cover;
  }

  &__content {
    padding: $spacing-big;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__meta {
    font-size: 0.75rem;
    line-height: 1.15;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-small;

    &-category {
      $polygon: calc(100% - 0.5rem);

      clip-path: polygon($polygon 0%, 100% 50%, $polygon 100%, 0% 100%, 0% 0%);
      background-color: $bg-yellow;
      padding: $spacing-micro $spacing-tiny;
      padding-right: 1rem;
      position: relative;
    }

    &-date {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-size: $baseline * 4.5;
    margin-bottom: $spacing-normal;
  }

  &__excerpt {
    margin-bottom: $spacing-normal;
  }

  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
