.c-box-link {
  color: inherit;
  background-color: $bg-white;
  box-shadow: $global-shadow;
  border-radius: $global-radius;
  padding: $spacing-big;
  transition: opacity 250ms ease-in-out;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-big;

  &:hover {
    color: inherit;
    opacity: 0.75;
  }

  &__footer {
    color: $text-gray;
    font-weight: 600;
    font-size: 0.8125rem;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
  }
}
