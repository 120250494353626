.c-player-card {
  border-radius: 32px;
  box-shadow: $global-shadow;
  overflow: hidden;

  &--small-mobile {
    @include bp-down(medium) {
      max-width: 128px;
      min-width: 0 !important;
    }
  }
}
