/* ==========================================================================
   #LISTS
   ========================================================================== */

li {
  margin-bottom: $spacing-tiny;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: $spacing-normal;
    margin-top: $spacing-tiny;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: $spacing-normal;
  margin-left: $spacing-normal;
}
