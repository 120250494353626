/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;
  table-layout: auto;

  @include bp(medium) {
    min-width: 60%;
    max-width: 100%;
    width: auto;
  }

  &__container {
    overflow-x: auto;
  }

  &__th {
    background-color: $bg-yellow;
    color: $text-black;
    white-space: nowrap;
    text-align: left;
  }

  &__th,
  &__td {
    padding: $spacing-tiny $spacing-small;
  }

  &__tr {
    &:nth-child(even) {
      background-color: $color-yellow-light;
    }
  }

  &--margin-top {
    margin-top: $spacing-great;
  }
}

/* Equal-width table cells.
   ========================================================================== */

/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */

.o-table--fixed {
  table-layout: fixed;
}
