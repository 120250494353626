.u-text {
  &--heading-1 {
    font-size: 2rem;
    line-height: 1.25;
  }

  &--heading-2 {
    font-size: 1.75rem;
    line-height: 1.35;
  }

  &--heading-3 {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  &--heading-4 {
    font-size: 1.25rem;
    line-height: 1.35;
  }

  /* Colors */
  &--red {
    color: $color-error;
  }

  &--yellow {
    color: $color-yellow;
  }

  &--white {
    color: $color-white;
  }
}

/* Font weights */
.u-font--bold {
  font-weight: 700;
}

.u-font--semibold {
  font-weight: 600;
}
