/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  color: $color-text;
  background-color: $bg-yellow;

  a {
    color: inherit;
  }

  @include bp(large) {
    color: $color-text-invert;
    background: linear-gradient(45deg, $bg-black 50%, $bg-yellow 50%);
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__address {
    width: 100%;

    @include bp(medium) {
      width: 50%;
      padding-right: $spacing-normal;
    }

    @include bp-down(medium) {
      text-align: center;
      margin-bottom: $spacing-large;
    }
  }

  &__copyright {
    color: $text-black;
    width: 100%;
    text-align: center;

    @include bp(medium) {
      width: 50%;
      padding-left: $spacing-normal;
      text-align: right;
      align-self: flex-end;
    }
  }

  &__logo {
    max-width: 200px;
    margin-bottom: $spacing-large;
  }
}
