.c-card {
  background-color: $bg-white;
  border-radius: 32px;
  box-shadow: $global-shadow;
  overflow: hidden;
  max-width: 620px;
  margin: 0 auto;
  padding: $spacing-great $spacing-small;

  @include bp(large) {
    padding: $spacing-great;
  }
}
