.c-title {
  color: $text-black;
  margin-bottom: $spacing-great;

  &--margin-bottom {
    margin-bottom: $spacing-huge;
  }

  &__label {
    padding: $spacing-tiny $spacing-big;
    display: inline-block;
    position: relative;
    font-weight: 600;
    font-size: 1rem;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 25px;
      height: 100%;
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    }

    &--yellow,
    &--yellow::after {
      background-color: $bg-yellow;
    }

    &--black,
    &--black::after {
      background-color: $bg-black;
      color: $text-white;
    }
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
h2.c-title {
  font-size: 2.5rem;
  line-height: 3rem;
}
