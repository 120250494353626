/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 0.8125rem; /* 13px */
  line-height: 1.5;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-top: 0 !important;
  scroll-behavior: smooth;

  @include bp(medium) {
    font-size: 0.875rem; /* 14px */
  }

  @include bp(large) {
    font-size: 1rem; /* 15px */
  }
}
