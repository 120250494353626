.c-social-icons {
  display: flex;
  position: absolute;
  margin: 0;

  @include bp(medium) {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    top: 0;
    right: 0;
  }

  @include bp-down(medium) {
    width: 100vw;
    top: 100%;
    left: 0;
  }

  &__item {
    list-style: none;
    margin: 0;
    box-shadow: $global-shadow;

    @include bp-down(medium) {
      width: 25%;
    }
  }

  &__link {
    background-color: $color-yellow;
    width: 64px;
    height: 64px;

    @include bp-down(medium) {
      width: 100%;
    }
  }
}
