.c-cookies {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;

  @include bp-down(medium) {
    padding: 0 $spacing-small;
  }

  &.is-active {
    display: block;
  }

  &__container {
    background-color: $color-bg;
    border-radius: $global-radius;
    box-shadow: $global-shadow;
    width: 100%;
    padding: $spacing-great;
    margin-bottom: $spacing-big;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include bp-down(medium) {
      flex-direction: column;
      padding: $spacing-normal;
    }
  }

  &__text {
    width: 100%;

    @include bp(medium) {
      margin-right: $spacing-huge;
    }
  }

  &__btn {
    margin-top: $spacing-small;

    @include bp(medium) {
      margin: 0 0 0 auto;
    }
  }
}
