.c-flat-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;

  &--black {
    color: $text-black;
  }
}
