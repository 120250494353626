.o-flex {
  display: flex;

  &--wrap {
    flex-wrap: wrap;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-evenly {
    justify-content: space-evenly;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--items-center {
    align-items: center;
  }

  &--col {
    flex-direction: column;
  }
}
