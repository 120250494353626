/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  &__image-container {
    padding-bottom: 56%;
    position: relative;
    overflow: hidden;
    margin-bottom: $spacing-normal;
  }

  &__title {
    font-size: 2rem;
    line-height: 1.35;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: $spacing-big;
  }

  &__content {
    line-height: 2;
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.wp-block-quote {
  background-color: $bg-gray;
  border-radius: 1rem;
  padding: $spacing-big;
  position: relative;

  cite {
    font-weight: bold;
    font-size: 0.85rem;
    line-height: 1;

    &::before {
      content: '~ ';
    }
  }

  p {
    margin-bottom: $spacing-small;
  }
}
