/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;

  @include bp-down(large) {
    padding: 0 $spacing-small;
  }

  &--thin {
    max-width: 960px;
  }
}
