/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  $self: &;

  display: flex;
  background-color: $bg-black;

  @include bp-down(medium) {
    $top: 41px + ($spacing-tiny * 2);

    flex-direction: column;
    width: 100%;
    padding: $spacing-big $spacing-small;
    position: absolute;
    left: 0;
    top: $top;
    transform: translateX(-110%);
    transition: transform $global-transition-time $global-transition-effect;
    box-shadow: $global-shadow;

    &.is-active {
      transform: translateX(0);
    }
  }

  &__list {
    display: flex;
    margin: 0 0 0 $spacing-big;

    @include bp-down(medium) {
      flex-direction: column;
      justify-content: center;
      margin: 0;
    }
  }

  &__item {
    font-weight: bold;
    list-style: none;
    margin: 0 $spacing-normal 0 0;
    padding: 0;

    @include bp-down(medium) {
      margin-right: 0;

      &:last-child {
        margin-bottom: $spacing-small;
      }
    }
  }

  &__link {
    color: $text-white;
    display: block;
    padding: $spacing-tiny 0;
    margin: 0 $spacing-tiny;
    text-decoration: none;
    text-align: center;
    position: relative;

    &:hover,
    &:focus {
      color: $text-yellow;
    }

    &::before {
      @include lineForLink();
    }

    &:hover::before,
    &:focus::before {
      @include lineForLinkHover();
    }
  }

  .current-menu-item &__link {
    color: $text-yellow;

    &::before {
      @include lineForLinkHover();
    }
  }

  &__dropdown {
    display: none;
    flex-direction: column;
    margin: 0;
    position: absolute;

    #{$self}__item:hover & {
      display: flex;
    }
  }

  &__subitem {
    list-style: none;
    margin: 0;
  }

  &__sublink {
    background: $color-white;
    border-bottom: 1px solid $color-border;
    display: block;
    padding: 0.5em;
    text-decoration: none;
  }

  .current-menu-item &__sublink {
    border-bottom-color: $color-black;
  }

  &__logo {
    margin: 0 auto;
  }
}
