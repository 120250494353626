.c-icons {
  &__container {
    display: flex;
    justify-content: space-evenly;

    @include bp-down(medium) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    padding: $spacing-big $spacing-great;
  }

  &__image {
    max-width: 200px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }

  &__text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin-top: $spacing-large;
    text-align: center;
  }
}
