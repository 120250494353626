.c-section {
  &-content,
  &-image {
    order: 1;
  }

  &--image-right &-image {
    order: 2;
  }
}
