.c-hero {
  &__section {
    position: relative;

    .o-wrapper {
      position: relative;
      z-index: 1;
    }
  }

  &__bg {
    background-position: bottom -10rem right -6rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.1;

    @include bp(large) {
      background-position: bottom 1rem center;
      background-size: auto;
    }
  }

  &__title {
    font-size: 2.75rem;
    line-height: 1.25;
    margin-bottom: $spacing-great;

    strong {
      color: $text-yellow;
    }
  }
}
