.o-link {
  color: inherit;
  text-decoration: none;
  transition: opacity $global-transition-time $global-transition-effect;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
}
