/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.c-button {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-weight: 700;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: $spacing-tiny $spacing-normal;
  border-radius: $global-radius;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 300ms $global-transition-effect;

  /* Loading effect
   ========================================================================== */

  &.is-loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.25rem;
      height: 1.25rem;
      margin-top: -0.6125rem;
      margin-left: -0.6125rem;
      border-radius: 50%;
      border: 2px solid $border-black;
      border-color: $border-black transparent $border-black transparent;
      animation: rotateAnimation 1.2s linear infinite;
    }

    span {
      opacity: 0;
    }
  }

  /* Style variants
   ========================================================================== */

  &--yellow {
    color: $text-black;
    background-color: $bg-yellow;
    border: 2px solid $border-yellow;
    transition: filter 300ms $global-transition-effect;

    &:focus,
    &:hover {
      color: $text-black;
      filter: brightness(1.15);
    }
  }

  &--black {
    color: $text-white;
    background-color: $bg-black;
    border: 2px solid $border-black;
    transition: opacity 300ms $global-transition-effect;

    &:focus,
    &:hover {
      color: $text-white;
      opacity: 0.75;
    }
  }

  &--black-outline {
    color: $text-black;
    border: 2px solid $border-black;
    transition: background-color 300ms $global-transition-effect;

    &:focus,
    &:hover {
      color: $text-white;
      background-color: $bg-black;
    }
  }

  &.is-disabled {
    background-color: $bg-gray;
    border-color: $border-dark-grey;
    cursor: auto;
    pointer-events: none;

    &:focus,
    &:hover {
      background-color: $bg-gray;
      border-color: $border-dark-grey;
      filter: none;
    }
  }
}

.c-btn .c-btn--secondary {
  color: $color-text-invert;
  border: 2px solid $color-text-invert;

  &:focus,
  &:hover {
    color: $color-text;
    background-color: $color-bg;
  }
}

.c-btn--tertiary {
  color: $color-secondary;
  border: 2px solid $color-secondary;

  &:focus,
  &:hover {
    color: $color-text-invert;
    background-color: $color-secondary;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--fixed {
  min-width: 200px;
}
