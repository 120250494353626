.c-footer-nav {
  &__list {
    @include bp(medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__link {
    font-weight: 700;

    @include bp(large) {
      color: $color-text-invert;
    }
  }

  &__item {
    @include bp(medium) {
      margin-right: $spacing-big;
    }
  }
}
