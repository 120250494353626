.c-icon {
  svg {
    width: 100%;
    height: 100%;
  }

  &--xs {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--sm {
    width: 1rem;
    height: 1rem;
  }

  &--md {
    width: 1.25rem;
    height: 1.25rem;
  }

  &--lg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--right {
    margin-left: $spacing-tiny;
  }

  &--left {
    margin-right: $spacing-tiny;
  }
}
