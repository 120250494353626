.u-bg--gray {
  background-color: $bg-gray;
}

.u-margin {
  &--tiny {
    margin: $spacing-small;
  }
}

.u-margin-top {
  &--small {
    margin-bottom: $spacing-small;
  }

  &--big {
    margin-top: $spacing-big;
  }

  &--great {
    margin-top: $spacing-great;
  }

  &--massive {
    margin-top: $spacing-massive;
  }

  &--auto {
    margin-top: auto;
  }

  @include bp-down(medium) {
    &--huge\@medium-down {
      margin-top: $spacing-huge;
    }
  }
}

.u-margin-bottom {
  &--small {
    margin-bottom: $spacing-small;
  }

  &--big {
    margin-bottom: $spacing-big;
  }

  &--great {
    margin-bottom: $spacing-great;
  }

  &--huge {
    margin-bottom: $spacing-huge;
  }

  &--massive {
    margin-bottom: $spacing-massive;
  }
}

.u-margin-left--tiny {
  margin-left: $spacing-tiny;
}

.u-margin-right--tiny {
  margin-right: $spacing-tiny;
}

.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-bottom-micro {
  margin-bottom: $spacing-micro;
}

.u-padding-left--normal {
  padding-left: $spacing-normal;
}

.u-font-size--huge {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.u-font-size--larger {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.u-text--bold {
  font-weight: bold;
}

.u-color--primary {
  color: $color-yellow;
}

.u-color--secondary {
  color: $color-secondary;
}

.u-width--xs {
  min-width: 160px;
  width: 160px;
}

.u-width--sm {
  min-width: 192px;
  width: 192px;
}

.u-size--md {
  width: 32px;
  aspect-ratio: 1 / 1;
}

@include bp-down(medium) {
  .admin-bar {
    margin-top: -46px;
  }
}

@include bp(medium) {
  .admin-bar .c-header__container {
    top: 32px;
  }
}

.editor-styles-wrapper .wp-block:not(.wp-block-post-title) {
  max-width: 100%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}
