.c-hamburger {
  $self: &;

  @include clearButton();

  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;

  @include bp(medium) {
    display: none;
  }

  &__line {
    background-color: $bg-white;
    border-radius: $global-radius;
    width: 24px;
    height: 2px;
    position: relative;
    transition: background-color 300ms $global-transition-effect;

    /* stylelint-disable-next-line prettier/prettier */
    &::before, &::after {
      content: '';
      background-color: $bg-white;
      border-radius: $global-radius;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      transition: transform $global-transition-time $global-transition-effect;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }

  &.is-active {
    #{$self}__line {
      background-color: $bg-black;

      &::before {
        transform: translateY(6px) rotate(45deg);
      }

      &::after {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
}
