.c-match {
  &__header {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &--away &__header {
    div {
      order: 2;
    }

    div:first-child {
      order: 3;
    }

    div:last-child {
      order: 1;
    }
  }

  &__club-crest {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp(medium) {
      height: 150px;
    }

    img {
      height: 100%;
    }
  }

  &__club-name {
    font-size: 1.15rem;
    margin-top: $spacing-small;
    font-weight: bold;
  }

  &__result {
    font-size: 4rem;
    padding-top: $spacing-tiny;
    display: flex;
    justify-content: center;
    gap: $spacing-small;

    @include bp(medium) {
      padding-top: $spacing-huge;
    }
  }

  &--away &__result {
    flex-direction: row-reverse;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-big;
    margin-top: $spacing-big;
  }

  &__pitch {
    /* stylelint-disable-next-line prettier/prettier */
    background: linear-gradient(to bottom, green 10%, darkgreen 10%, darkgreen 20%, green 20%, green 30%, darkgreen 30%, darkgreen 40%, green 40%, green 50%, darkgreen 50%, darkgreen 60%, green 60%, green 70%, darkgreen 70%, darkgreen 80%, green 80%, green 90%, darkgreen 90%);
    width: 100%;
    aspect-ratio: 3 / 4;
    position: relative;
    padding: $spacing-big;

    &-container {
      width: 100%;
      height: 100%;
      border: 3px solid $border-white;
    }

    &-lines {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__penalty-box {
    border: 3px solid $border-white;
    width: 60%;
    height: 20%;
    position: relative;

    &::after {
      content: '';
      border: 3px solid $border-white;
      width: 60%;
      height: 40%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &--top {
      margin: -3px auto 0;

      &::after {
        top: 0;
        margin-top: -3px;
      }
    }

    &--bottom {
      margin: 0 auto -3px;

      &::after {
        bottom: 0;
        margin-bottom: -3px;
      }
    }
  }

  &__middle-box {
    height: 28%;
    position: relative;

    &::before {
      content: '';
      border-top: 3px solid $border-white;
      width: 100%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
    }

    &::after {
      content: '';
      border: 3px solid $border-white;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      aspect-ratio: 1 / 1;
      transform: translate(-50%, -50%);
    }
  }

  &__player {
    width: 30%;
  }

  &__squad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5% $spacing-tiny $spacing-great;

    @include bp(medium) {
      padding: 20% $spacing-huge $spacing-great;
    }

    &-row {
      display: flex;
      justify-content: center;
      position: relative;

      &--2 {
        justify-content: space-between;
      }

      &--3 {
        margin-bottom: -8%;
      }

      &--3 .c-match__player:nth-child(2) {
        position: absolute;
        top: -120%;
        left: 50%;
        transform: translate(-50%);

        /* stylelint-disable-next-line max-nesting-depth */
        @include bp(medium) {
          top: -150%;
        }
      }

      &--4 {
        justify-content: space-between;
        margin-bottom: -$spacing-great;
      }
    }
  }

  &__shirt {
    width: 40px;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
  }

  &__player-name {
    background-color: $bg-yellow;
    color: $text-black;
    text-decoration: none;
    font-size: 0.9rem;
    padding: calc($spacing-tiny/4) $spacing-tiny;
    margin-top: $spacing-tiny;
    border-radius: $global-radius;
    white-space: nowrap;

    &:focus,
    &:hover {
      color: $text-black;
    }
  }
}
