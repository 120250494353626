.c-label {
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: $global-radius;
  padding: $spacing-tiny $spacing-small;
  margin: 0 $spacing-small $spacing-tiny 0;
  display: inline-flex;

  &--gray {
    background-color: $bg-gray;
  }

  &--green {
    background-color: $bg-green;
  }

  &--red {
    background-color: $bg-red;
  }

  &--yellow {
    background-color: $bg-yellow;
  }

  &--mb-none {
    margin-bottom: 0;
  }

  &--xs {
    padding: $spacing-micro $spacing-tiny;
    margin-right: $spacing-tiny;
  }
}
