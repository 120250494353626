/*
  Project: Jeziorak
  Author: Adam Kanigowski
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 20px;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 16px;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 0.8125rem; /* 13px */
  line-height: 1.5;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-top: 0 !important;
  scroll-behavior: smooth;
}
@media (min-width: 768px) {
  html {
    font-size: 0.875rem; /* 14px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 1rem; /* 15px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: rgb(255, 206, 0);
  text-decoration: underline;
}
a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 8px;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 16px;
  margin-top: 8px;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 16px;
  margin-left: 16px;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

.o-flex {
  display: flex;
}
.o-flex--wrap {
  flex-wrap: wrap;
}
.o-flex--justify-center {
  justify-content: center;
}
.o-flex--justify-between {
  justify-content: space-between;
}
.o-flex--justify-evenly {
  justify-content: space-evenly;
}
.o-flex--justify-end {
  justify-content: flex-end;
}
.o-flex--items-center {
  align-items: center;
}
.o-flex--col {
  flex-direction: column;
}

.o-grid {
  display: grid;
  width: 100%;
}
.o-grid-cols--1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.o-grid-cols--3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media (min-width: 768px) {
  .o-grid-cols--2\@medium {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .o-grid-cols--3\@medium {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.o-grid-items--center {
  align-items: center;
}
.o-grid-gap--small {
  gap: 12px;
}
.o-grid-gap--great {
  gap: 32px;
}

.o-link {
  color: inherit;
  text-decoration: none;
  transition: opacity 400ms ease-in-out;
}
.o-link:hover, .o-link:focus {
  opacity: 0.75;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

.o-main {
  padding-top: 60px;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-section {
  padding: 40px 0;
}
@media (min-width: 768px) {
  .o-section {
    padding: 80px 0;
  }
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
  table-layout: auto;
}
@media (min-width: 768px) {
  .o-table {
    min-width: 60%;
    max-width: 100%;
    width: auto;
  }
}
.o-table__container {
  overflow-x: auto;
}
.o-table__th {
  background-color: rgb(255, 206, 0);
  color: #000;
  white-space: nowrap;
  text-align: left;
}
.o-table__th, .o-table__td {
  padding: 8px 12px;
}
.o-table__tr:nth-child(even) {
  background-color: rgba(255, 206, 0, 0.15);
}
.o-table--margin-top {
  margin-top: 32px;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (max-width: 1023.98px) {
  .o-wrapper {
    padding: 0 12px;
  }
}
.o-wrapper--thin {
  max-width: 960px;
}

.c-404 {
  padding-top: 40px;
}
.c-404__title {
  color: rgb(255, 206, 0);
  font-size: 10rem;
  line-height: 10rem;
  text-align: center;
}
.c-404__text {
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
}

.c-article {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: opacity 250ms;
  display: flex;
  flex-direction: column;
}
.c-article:hover {
  opacity: 0.75;
}
.c-article__image {
  padding-bottom: 56.25%;
  background-position: center center;
  background-size: cover;
}
.c-article__content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.c-article__meta {
  font-size: 0.75rem;
  line-height: 1.15;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.c-article__meta-category {
  clip-path: polygon(calc(100% - 0.5rem) 0%, 100% 50%, calc(100% - 0.5rem) 100%, 0% 100%, 0% 0%);
  background-color: rgb(255, 206, 0);
  padding: 4px 8px;
  padding-right: 1rem;
  position: relative;
}
.c-article__meta-date {
  display: flex;
  align-items: center;
}
.c-article__title {
  font-size: 18px;
  margin-bottom: 16px;
}
.c-article__excerpt {
  margin-bottom: 16px;
}
.c-article__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-box-link {
  color: inherit;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  transition: opacity 250ms ease-in-out;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.c-box-link:hover {
  color: inherit;
  opacity: 0.75;
}
.c-box-link__footer {
  color: #333;
  font-weight: 600;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.c-box-link p {
  margin-bottom: 0;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
.c-button {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-weight: 700;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 12px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 300ms ease-in-out;
  /* Loading effect
   ========================================================================== */
  /* Style variants
   ========================================================================== */
}
.c-button.is-loading::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.6125rem;
  margin-left: -0.6125rem;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: rotateAnimation 1.2s linear infinite;
}
.c-button.is-loading span {
  opacity: 0;
}
.c-button--yellow {
  color: #000;
  background-color: rgb(255, 206, 0);
  border: 2px solid rgb(255, 206, 0);
  transition: filter 300ms ease-in-out;
}
.c-button--yellow:focus, .c-button--yellow:hover {
  color: #000;
  filter: brightness(1.15);
}
.c-button--black {
  color: #fff;
  background-color: #000;
  border: 2px solid #000;
  transition: opacity 300ms ease-in-out;
}
.c-button--black:focus, .c-button--black:hover {
  color: #fff;
  opacity: 0.75;
}
.c-button--black-outline {
  color: #000;
  border: 2px solid #000;
  transition: background-color 300ms ease-in-out;
}
.c-button--black-outline:focus, .c-button--black-outline:hover {
  color: #fff;
  background-color: #000;
}
.c-button.is-disabled {
  background-color: #e2e2e2;
  border-color: #333;
  cursor: auto;
  pointer-events: none;
}
.c-button.is-disabled:focus, .c-button.is-disabled:hover {
  background-color: #e2e2e2;
  border-color: #333;
  filter: none;
}

.c-btn .c-btn--secondary {
  color: #fff;
  border: 2px solid #fff;
}
.c-btn .c-btn--secondary:focus, .c-btn .c-btn--secondary:hover {
  color: #000;
  background-color: #fff;
}

.c-btn--tertiary {
  color: #000;
  border: 2px solid #000;
}
.c-btn--tertiary:focus, .c-btn--tertiary:hover {
  color: #fff;
  background-color: #000;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--fixed {
  min-width: 200px;
}

.c-card {
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 620px;
  margin: 0 auto;
  padding: 32px 12px;
}
@media (min-width: 1024px) {
  .c-card {
    padding: 32px;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-cookies {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;
}
@media (max-width: 767.98px) {
  .c-cookies {
    padding: 0 12px;
  }
}
.c-cookies.is-active {
  display: block;
}
.c-cookies__container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 32px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  .c-cookies__container {
    flex-direction: column;
    padding: 16px;
  }
}
.c-cookies__text {
  width: 100%;
}
@media (min-width: 768px) {
  .c-cookies__text {
    margin-right: 40px;
  }
}
.c-cookies__btn {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .c-cookies__btn {
    margin: 0 0 0 auto;
  }
}

.c-flat-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
}
.c-flat-button--black {
  color: #000;
}

@media (min-width: 768px) {
  .c-footer-nav__list {
    display: flex;
    flex-wrap: wrap;
  }
}
.c-footer-nav__link {
  font-weight: 700;
}
@media (min-width: 1024px) {
  .c-footer-nav__link {
    color: #fff;
  }
}
@media (min-width: 768px) {
  .c-footer-nav__item {
    margin-right: 20px;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  color: #000;
  background-color: rgb(255, 206, 0);
}
.c-footer a {
  color: inherit;
}
@media (min-width: 1024px) {
  .c-footer {
    color: #fff;
    background: linear-gradient(45deg, #000 50%, rgb(255, 206, 0) 50%);
  }
}
.c-footer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.c-footer__address {
  width: 100%;
}
@media (min-width: 768px) {
  .c-footer__address {
    width: 50%;
    padding-right: 16px;
  }
}
@media (max-width: 767.98px) {
  .c-footer__address {
    text-align: center;
    margin-bottom: 24px;
  }
}
.c-footer__copyright {
  color: #000;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__copyright {
    width: 50%;
    padding-left: 16px;
    text-align: right;
    align-self: flex-end;
  }
}
.c-footer__logo {
  max-width: 200px;
  margin-bottom: 24px;
}

.c-hamburger {
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .c-hamburger {
    display: none;
  }
}
.c-hamburger__line {
  background-color: #fff;
  border-radius: 12px;
  width: 24px;
  height: 2px;
  position: relative;
  transition: background-color 300ms ease-in-out;
  /* stylelint-disable-next-line prettier/prettier */
}
.c-hamburger__line::before, .c-hamburger__line::after {
  content: "";
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  transition: transform 400ms ease-in-out;
}
.c-hamburger__line::before {
  top: -6px;
}
.c-hamburger__line::after {
  bottom: -6px;
}
.c-hamburger.is-active .c-hamburger__line {
  background-color: #000;
}
.c-hamburger.is-active .c-hamburger__line::before {
  transform: translateY(6px) rotate(45deg);
}
.c-hamburger.is-active .c-hamburger__line::after {
  transform: translateY(-6px) rotate(-45deg);
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  height: 100%;
}
.c-header__container {
  background-color: #000;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.c-header__wrapper {
  position: relative;
  height: 100%;
}
.c-header__wrapper::before, .c-header__wrapper::after {
  content: "";
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
}
.c-header__wrapper::before {
  /* stylelint-disable-next-line prettier/prettier */
  background: linear-gradient(45deg, #000 50%, rgb(255, 206, 0) 50%, rgb(255, 206, 0) 55%, #000 55%, #000 60%, rgb(255, 206, 0) 60%, rgb(255, 206, 0) 65%, #000 65%, #000 70%, rgb(255, 206, 0) 70%, rgb(255, 206, 0) 75%, #000 75%);
  left: -300px;
}
.c-header__wrapper::after {
  /* stylelint-disable-next-line prettier/prettier */
  background: linear-gradient(45deg, #000 25%, rgb(255, 206, 0) 25%, rgb(255, 206, 0) 30%, #000 30%, #000 35%, rgb(255, 206, 0) 35%, rgb(255, 206, 0) 40%, #000 40%, #000 45%, rgb(255, 206, 0) 45%, rgb(255, 206, 0) 50%, #000 50%);
  right: -300px;
}
.c-header__logo {
  color: #fff;
  fill: #fff;
  height: 30px;
}
@media (min-width: 768px) {
  .c-header__logo {
    height: 40px;
  }
}

.c-hero-video {
  position: relative;
}
.c-hero-video__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.c-hero-video__video {
  padding-bottom: 40%;
  position: relative;
  overflow: hidden;
}
.c-hero-video__video::after {
  content: "";
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}
.c-hero-video__video video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}
.c-hero-video__content {
  display: flex;
  justify-content: center;
  padding: 80px;
}

.c-hero__section {
  position: relative;
}
.c-hero__section .o-wrapper {
  position: relative;
  z-index: 1;
}
.c-hero__bg {
  background-position: bottom -10rem right -6rem;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0.1;
}
@media (min-width: 1024px) {
  .c-hero__bg {
    background-position: bottom 1rem center;
    background-size: auto;
  }
}
.c-hero__title {
  font-size: 2.75rem;
  line-height: 1.25;
  margin-bottom: 32px;
}
.c-hero__title strong {
  color: rgb(255, 206, 0);
}

.c-icon svg {
  width: 100%;
  height: 100%;
}
.c-icon--xs {
  width: 0.75rem;
  height: 0.75rem;
}
.c-icon--sm {
  width: 1rem;
  height: 1rem;
}
.c-icon--md {
  width: 1.25rem;
  height: 1.25rem;
}
.c-icon--lg {
  width: 1.5rem;
  height: 1.5rem;
}
.c-icon--right {
  margin-left: 8px;
}
.c-icon--left {
  margin-right: 8px;
}

.c-label {
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 12px;
  padding: 8px 12px;
  margin: 0 12px 8px 0;
  display: inline-flex;
}
.c-label--gray {
  background-color: #e2e2e2;
}
.c-label--green {
  background-color: #a9f38c;
}
.c-label--red {
  background-color: #ef4545;
}
.c-label--yellow {
  background-color: rgb(255, 206, 0);
}
.c-label--mb-none {
  margin-bottom: 0;
}
.c-label--xs {
  padding: 4px 8px;
  margin-right: 8px;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  background-color: #000;
}
@media (max-width: 767.98px) {
  .c-main-nav {
    flex-direction: column;
    width: 100%;
    padding: 20px 12px;
    position: absolute;
    left: 0;
    top: 57px;
    transform: translateX(-110%);
    transition: transform 400ms ease-in-out;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
  .c-main-nav.is-active {
    transform: translateX(0);
  }
}
.c-main-nav__list {
  display: flex;
  margin: 0 0 0 20px;
}
@media (max-width: 767.98px) {
  .c-main-nav__list {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}
.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 16px 0 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .c-main-nav__item {
    margin-right: 0;
  }
  .c-main-nav__item:last-child {
    margin-bottom: 12px;
  }
}
.c-main-nav__link {
  color: #fff;
  display: block;
  padding: 8px 0;
  margin: 0 8px;
  text-decoration: none;
  text-align: center;
  position: relative;
}
.c-main-nav__link:hover, .c-main-nav__link:focus {
  color: rgb(255, 206, 0);
}
.c-main-nav__link::before {
  content: "";
  background-color: rgb(255, 206, 0);
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 400ms ease-in-out;
}
.c-main-nav__link:hover::before, .c-main-nav__link:focus::before {
  transform: scaleX(1);
}
.current-menu-item .c-main-nav__link {
  color: rgb(255, 206, 0);
}
.current-menu-item .c-main-nav__link::before {
  transform: scaleX(1);
}
.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}
.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid rgb(255, 206, 0);
  display: block;
  padding: 0.5em;
  text-decoration: none;
}
.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}
.c-main-nav__logo {
  margin: 0 auto;
}

.c-match-block {
  color: #000;
  text-decoration: none;
  max-width: 720px;
}
.c-match-block:hover {
  color: #000;
}
.c-match-block__club {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-match-block__club-crest {
  width: auto;
  height: 50px !important;
}
@media (min-width: 768px) {
  .c-match-block__club-crest {
    height: 80px !important;
  }
}
.c-match-block__club-name {
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 12px;
}
.c-match-block__details {
  font-weight: bold;
  display: inline-block;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .c-match-block__details {
    margin: 0 16px;
  }
}
.c-match-block__result {
  background-color: rgb(255, 206, 0);
  color: #000;
  font-size: 1.25rem;
  line-height: 1;
  text-align: center;
  padding: 12px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.c-match-block__result--away {
  flex-direction: row-reverse;
}
.c-match-block__meta {
  color: #fff;
  background-color: #000;
  font-size: 0.85rem;
  line-height: 1;
  text-align: center;
  padding: 12px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.c-match-block--away > :nth-child(1) {
  order: 3;
}
.c-match-block--away > :nth-child(2) {
  order: 2;
}
.c-match-block--away > :nth-child(3) {
  order: 1;
}
.c-match-block--text-white {
  color: #fff;
}
.c-match-block--text-white:hover {
  color: #fff;
}
.c-match-block--large .c-match-block__club-name {
  font-size: 1.5rem;
  line-height: 1.25rem;
  margin-top: 20px;
}
.c-match-block--large .c-match-block__result {
  font-size: 1.5rem;
}
.c-match-block--large .c-match-block__meta {
  font-size: 1rem;
}

.c-match__header {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.c-match--away .c-match__header div {
  order: 2;
}
.c-match--away .c-match__header div:first-child {
  order: 3;
}
.c-match--away .c-match__header div:last-child {
  order: 1;
}
.c-match__club-crest {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .c-match__club-crest {
    height: 150px;
  }
}
.c-match__club-crest img {
  height: 100%;
}
.c-match__club-name {
  font-size: 1.15rem;
  margin-top: 12px;
  font-weight: bold;
}
.c-match__result {
  font-size: 4rem;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  gap: 12px;
}
@media (min-width: 768px) {
  .c-match__result {
    padding-top: 40px;
  }
}
.c-match--away .c-match__result {
  flex-direction: row-reverse;
}
.c-match__meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.c-match__pitch {
  /* stylelint-disable-next-line prettier/prettier */
  background: linear-gradient(to bottom, green 10%, darkgreen 10%, darkgreen 20%, green 20%, green 30%, darkgreen 30%, darkgreen 40%, green 40%, green 50%, darkgreen 50%, darkgreen 60%, green 60%, green 70%, darkgreen 70%, darkgreen 80%, green 80%, green 90%, darkgreen 90%);
  width: 100%;
  aspect-ratio: 3/4;
  position: relative;
  padding: 20px;
}
.c-match__pitch-container {
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
}
.c-match__pitch-lines {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.c-match__penalty-box {
  border: 3px solid #fff;
  width: 60%;
  height: 20%;
  position: relative;
}
.c-match__penalty-box::after {
  content: "";
  border: 3px solid #fff;
  width: 60%;
  height: 40%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.c-match__penalty-box--top {
  margin: -3px auto 0;
}
.c-match__penalty-box--top::after {
  top: 0;
  margin-top: -3px;
}
.c-match__penalty-box--bottom {
  margin: 0 auto -3px;
}
.c-match__penalty-box--bottom::after {
  bottom: 0;
  margin-bottom: -3px;
}
.c-match__middle-box {
  height: 28%;
  position: relative;
}
.c-match__middle-box::before {
  content: "";
  border-top: 3px solid #fff;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 0;
}
.c-match__middle-box::after {
  content: "";
  border: 3px solid #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
}
.c-match__player {
  width: 30%;
}
.c-match__squad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5% 8px 32px;
}
@media (min-width: 768px) {
  .c-match__squad {
    padding: 20% 40px 32px;
  }
}
.c-match__squad-row {
  display: flex;
  justify-content: center;
  position: relative;
}
.c-match__squad-row--2 {
  justify-content: space-between;
}
.c-match__squad-row--3 {
  margin-bottom: -8%;
}
.c-match__squad-row--3 .c-match__player:nth-child(2) {
  position: absolute;
  top: -120%;
  left: 50%;
  transform: translate(-50%);
  /* stylelint-disable-next-line max-nesting-depth */
}
@media (min-width: 768px) {
  .c-match__squad-row--3 .c-match__player:nth-child(2) {
    top: -150%;
  }
}
.c-match__squad-row--4 {
  justify-content: space-between;
  margin-bottom: -32px;
}
.c-match__shirt {
  width: 40px;
  aspect-ratio: 1/1;
  margin: 0 auto;
}
.c-match__player-name {
  background-color: rgb(255, 206, 0);
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 2px 8px;
  margin-top: 8px;
  border-radius: 12px;
  white-space: nowrap;
}
.c-match__player-name:focus, .c-match__player-name:hover {
  color: #000;
}

.c-player-card {
  border-radius: 32px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .c-player-card--small-mobile {
    max-width: 128px;
    min-width: 0 !important;
  }
}

.c-player-details__avatar {
  width: 200px;
}

.c-players-slider {
  overflow: hidden;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post__image-container {
  padding-bottom: 56%;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}
.c-post__title {
  font-size: 2rem;
  line-height: 1.35;
}
.c-post__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-post__meta {
  display: flex;
  align-items: center;
  gap: 20px;
}
.c-post__content {
  line-height: 2;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.wp-block-quote {
  background-color: #e2e2e2;
  border-radius: 1rem;
  padding: 20px;
  position: relative;
}
.wp-block-quote cite {
  font-weight: bold;
  font-size: 0.85rem;
  line-height: 1;
}
.wp-block-quote cite::before {
  content: "~ ";
}
.wp-block-quote p {
  margin-bottom: 12px;
}

.c-section-content, .c-section-image {
  order: 1;
}
.c-section--image-right .c-section-image {
  order: 2;
}

.c-slider {
  position: relative;
}
.c-slider__navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-slider__btn {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 48px;
  height: 48px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 400ms ease-in-out;
}
.c-slider__btn--prev {
  transform: translateX(-15%);
}
@media (min-width: 768px) {
  .c-slider__btn--prev {
    transform: translateX(-50%);
  }
}
.c-slider__btn--next {
  transform: translateX(15%);
}
@media (min-width: 768px) {
  .c-slider__btn--next {
    transform: translateX(50%);
  }
}
.c-slider__btn--disabled {
  cursor: default;
  opacity: 0.5;
}

.swiper {
  padding-bottom: 20px !important;
  margin-bottom: -20px !important;
}

.c-social-icons {
  display: flex;
  position: absolute;
  margin: 0;
}
@media (min-width: 768px) {
  .c-social-icons {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    top: 0;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .c-social-icons {
    width: 100vw;
    top: 100%;
    left: 0;
  }
}
.c-social-icons__item {
  list-style: none;
  margin: 0;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .c-social-icons__item {
    width: 25%;
  }
}
.c-social-icons__link {
  background-color: rgb(255, 206, 0);
  width: 64px;
  height: 64px;
}
@media (max-width: 767.98px) {
  .c-social-icons__link {
    width: 100%;
  }
}

.c-title {
  color: #000;
  margin-bottom: 32px;
}
.c-title--margin-bottom {
  margin-bottom: 40px;
}
.c-title__label {
  padding: 8px 20px;
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 1rem;
}
.c-title__label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 25px;
  height: 100%;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
.c-title__label--yellow, .c-title__label--yellow::after {
  background-color: rgb(255, 206, 0);
}
.c-title__label--black, .c-title__label--black::after {
  background-color: #000;
  color: #fff;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
h2.c-title {
  font-size: 2.5rem;
  line-height: 3rem;
}

/* stylelint-disable selector-no-qualifying-type */
.wpcf7-form-control:not(input[type=submit]) {
  background-color: #fff;
  color: #000;
  border: 2px solid #ccc;
  border-radius: 12px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-top: 8px;
}
.wpcf7-form-control:not(input[type=submit]):focus {
  border-color: rgb(255, 206, 0);
}
.wpcf7-form-control:not(input[type=submit]):focus-visible {
  outline: none;
}
.wpcf7-textarea {
  min-height: 150px;
}
.wpcf7-response-output {
  border-radius: 12px;
  margin: 20px 0 0 0 !important;
  padding: 8px 16px !important;
  border: none !important;
}
form.invalid .wpcf7-response-output {
  background-color: #f9f494 !important;
}
form.failed .wpcf7-response-output {
  background-color: #ef4545 !important;
}
form.sent .wpcf7-response-output {
  background-color: #a9f38c;
}

/* stylelint-disable prettier/prettier */
.c-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 80px;
}
.c-contact__details {
  width: 100%;
}
@media (min-width: 1024px) {
  .c-contact__details {
    width: 50%;
  }
}
.c-contact__form-container {
  width: 100%;
}
@media (min-width: 1024px) {
  .c-contact__form-container {
    max-width: 570px;
    margin: 0 auto;
  }
}

/* stylelint-disable max-nesting-depth */
.c-employees__item {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .c-employees__item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .c-employees__item:nth-child(even) .c-employees__image {
    margin-left: auto;
  }
  .c-employees__item:nth-child(even) .c-employees__image-container {
    padding: 0 0 0 40px;
  }
}
.c-employees__image {
  max-width: 100% !important;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: block;
}
@media (max-width: 767.98px) {
  .c-employees__image {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.c-employees__image-container {
  width: 100%;
}
@media (min-width: 768px) {
  .c-employees__image-container {
    width: 33.33%;
    padding-right: 40px;
  }
}
.c-employees__content {
  width: 100%;
  background-color: rgba(255, 206, 0, 0.15);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .c-employees__content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 768px) {
  .c-employees__content {
    width: 66.66%;
    padding: 40px;
  }
}
.c-employees__title {
  margin: 20px 0;
  font-size: 2rem;
  line-height: 2.5rem;
}
@media (min-width: 768px) {
  .c-employees__title {
    margin: 0 0 20px;
  }
}
.c-employees__description {
  font-size: 1.25rem;
  line-height: 2rem;
}
.c-employees__description p:last-child {
  margin-bottom: 0;
}
.c-employees__job-titles {
  background-color: rgb(255, 206, 0);
  color: #fff;
  border-radius: 12px;
  font-size: 1.25rem;
  line-height: 1.45rem;
  font-weight: 700;
  padding: 12px 32px;
  margin-bottom: 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.c-icons__container {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 767.98px) {
  .c-icons__container {
    flex-direction: column;
    align-items: center;
  }
}
.c-icons__item {
  padding: 20px 32px;
}
.c-icons__image {
  max-width: 200px;
  height: 100px;
  display: block;
  margin: 0 auto;
}
.c-icons__text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
}

.c-map {
  width: 100%;
  padding: 0;
  background-color: #fff;
}
.c-map iframe {
  width: 100% !important;
  overflow: hidden;
  margin-bottom: -16px;
}

.c-offer {
  background-color: #fff;
}
.c-offer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c-offer__item-container {
  width: 100%;
  margin-top: 125px;
  margin-bottom: 60px;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .c-offer__item-container {
    width: 50%;
  }
  .c-offer__item-container:nth-child(even) {
    padding-left: 16px;
  }
  .c-offer__item-container:nth-child(odd) {
    padding-right: 16px;
  }
}
@media (min-width: 1024px) {
  .c-offer__item-container {
    width: 33.33%;
  }
  .c-offer__item-container:nth-child(3n+1) {
    padding-right: 16px;
  }
  .c-offer__item-container:nth-child(3n+2) {
    padding: 0 16px;
  }
  .c-offer__item-container:nth-child(3n+3) {
    padding-left: 16px;
  }
}
.c-offer__item {
  background-color: rgba(255, 206, 0, 0.15);
  border-radius: 12px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 125px 20px 20px;
  position: relative;
}
.c-offer__image-container {
  border-radius: 100%;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.c-offer__image-container::after {
  content: "";
  border: 6px solid rgba(255, 206, 0, 0.15);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.c-offer__image {
  border-radius: 100%;
  width: 250px;
  height: 250px;
}
.c-offer__title {
  color: rgb(255, 206, 0);
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.c-offer--col-2 .c-offer__item-container {
  width: 100%;
  margin-top: 125px;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .c-offer--col-2 .c-offer__item-container {
    width: 50%;
  }
  .c-offer--col-2 .c-offer__item-container:nth-child(even) {
    padding-left: 16px;
    padding-right: 0;
  }
  .c-offer--col-2 .c-offer__item-container:nth-child(odd) {
    padding-right: 16px;
  }
}
.c-offer__btn {
  display: flex;
  justify-content: center;
}

.c-posts-list {
  display: grid;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-text--center {
  text-align: center;
}
.u-text--left {
  text-align: left;
}
.u-text--right {
  text-align: right;
}

.u-bg--white {
  background-color: #fff;
}
.u-bg--black {
  background-color: #000;
}
.u-bg--gray {
  background-color: #e2e2e2;
}
.u-bg--yellow {
  background-color: rgb(255, 206, 0);
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.u-bg--gray {
  background-color: #e2e2e2;
}

.u-margin--tiny {
  margin: 12px;
}

.u-margin-top--small {
  margin-bottom: 12px;
}
.u-margin-top--big {
  margin-top: 20px;
}
.u-margin-top--great {
  margin-top: 32px;
}
.u-margin-top--massive {
  margin-top: 80px;
}
.u-margin-top--auto {
  margin-top: auto;
}
@media (max-width: 767.98px) {
  .u-margin-top--huge\@medium-down {
    margin-top: 40px;
  }
}

.u-margin-bottom--small {
  margin-bottom: 12px;
}
.u-margin-bottom--big {
  margin-bottom: 20px;
}
.u-margin-bottom--great {
  margin-bottom: 32px;
}
.u-margin-bottom--huge {
  margin-bottom: 40px;
}
.u-margin-bottom--massive {
  margin-bottom: 80px;
}

.u-margin-left--tiny {
  margin-left: 8px;
}

.u-margin-right--tiny {
  margin-right: 8px;
}

.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-bottom-micro {
  margin-bottom: 4px;
}

.u-padding-left--normal {
  padding-left: 16px;
}

.u-font-size--huge {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.u-font-size--larger {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.u-text--bold {
  font-weight: bold;
}

.u-color--primary {
  color: rgb(255, 206, 0);
}

.u-color--secondary {
  color: #000;
}

.u-width--xs {
  min-width: 160px;
  width: 160px;
}

.u-width--sm {
  min-width: 192px;
  width: 192px;
}

.u-size--md {
  width: 32px;
  aspect-ratio: 1/1;
}

@media (max-width: 767.98px) {
  .admin-bar {
    margin-top: -46px;
  }
}
@media (min-width: 768px) {
  .admin-bar .c-header__container {
    top: 32px;
  }
}
.editor-styles-wrapper .wp-block:not(.wp-block-post-title) {
  max-width: 100%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-text {
  /* Colors */
}
.u-text--heading-1 {
  font-size: 2rem;
  line-height: 1.25;
}
.u-text--heading-2 {
  font-size: 1.75rem;
  line-height: 1.35;
}
.u-text--heading-3 {
  font-size: 1.5rem;
  line-height: 1.5;
}
.u-text--heading-4 {
  font-size: 1.25rem;
  line-height: 1.35;
}
.u-text--red {
  color: #ef4545;
}
.u-text--yellow {
  color: rgb(255, 206, 0);
}
.u-text--white {
  color: #fff;
}

/* Font weights */
.u-font--bold {
  font-weight: 700;
}

.u-font--semibold {
  font-weight: 600;
}