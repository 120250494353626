/* stylelint-disable prettier/prettier */
.c-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 0;
  padding-bottom: $spacing-huge * 2;

  &__details {
    width: 100%;

    @include bp(large) {
      width: 50%;
    }
  }

  &__form-container {
    width: 100%;

    @include bp(large) {
      max-width: 570px;
      margin: 0 auto;
    }
  }
}
