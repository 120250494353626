.o-grid {
  display: grid;
  width: 100%;

  &-cols--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &-cols--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include bp(medium) {
    &-cols--2\@medium {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &-cols--3\@medium {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &-items--center {
    align-items: center;
  }

  &-gap--small {
    gap: $spacing-small;
  }

  &-gap--great {
    gap: $spacing-great;
  }
}
