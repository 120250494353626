/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  padding: $spacing-tiny * 1.25 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-black;
  height: 100%;

  &__container {
    background-color: $bg-black;
    box-shadow: $global-shadow;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &__wrapper {
    position: relative;
    height: 100%;

    &::before,
    &::after {
      content: '';
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
    }

    &::before {
      /* stylelint-disable-next-line prettier/prettier */
      background: linear-gradient(45deg, $color-black 50%, $color-yellow 50%, $color-yellow 55%, $color-black 55%, $color-black 60%, $color-yellow 60%, $color-yellow 65%, $color-black 65%, $color-black 70%, $color-yellow 70%, $color-yellow 75%, $color-black 75%);
      left: -300px;
    }

    &::after {
      /* stylelint-disable-next-line prettier/prettier */
      background: linear-gradient(45deg, $color-black 25%, $color-yellow 25%, $color-yellow 30%, $color-black 30%, $color-black 35%, $color-yellow 35%, $color-yellow 40%, $color-black 40%, $color-black 45%, $color-yellow 45%, $color-yellow 50%, $color-black 50%);
      right: -300px;
    }
  }

  &__logo {
    color: $text-white;
    fill: $text-white;
    height: 30px;

    @include bp(medium) {
      height: 40px;
    }
  }
}
