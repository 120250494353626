.c-hero-video {
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__video {
    padding-bottom: 40%;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      background-color: $bg-black;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: $spacing-massive;
  }
}
