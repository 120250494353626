.u-bg {
  &--white {
    background-color: $bg-white;
  }

  &--black {
    background-color: $bg-black;
  }

  &--gray {
    background-color: $bg-gray;
  }

  &--yellow {
    background-color: $bg-yellow;
  }
}
